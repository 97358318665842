<template>
    <vx-card title="Edit Picking Suggestion Form">
        <!-- <vs-table stripe border :data="pickingPlan"> -->
        <vs-table stripe border :data="data">
            <!-- <template slot="thead">
                <vs-th sort-key="">Delivery Order</vs-th>
                <vs-th sort-key="">Sales Order</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                <vs-th sort-key="">Delivery Type</vs-th>
            </template> -->
            <template slot="thead">
                <vs-th sort-key="">Delivery Plan</vs-th>
                <vs-th sort-key="">Picking List Number</vs-th>
                <vs-th sort-key="">Picking List Type</vs-th>
                <vs-th sort-key="">Sales Order Data</vs-th>
                <vs-th sort-key="">Delivery Order Data</vs-th>
                <vs-th sort-key="">Ship-to-Data</vs-th>
                <vs-th sort-key="">Request Delivery Data (RDD)</vs-th>
            </template>

            <!-- <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in pickingPlan">
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerName }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.CustomerAddress }}</vs-td>
                    <vs-td>{{ formatDate(tr.SalesOrder.DeliveryOrder.Date) }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryType }}</vs-td>
                </vs-tr>
            </template> -->

            <template>
            <template v-for="(t, indext) in data">
                <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingPlan">
                    <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.Code : '' }}</vs-td>
                    <vs-td>{{ indextr == 0 ? t.Code: '' }}</vs-td>
                    <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryType : '' }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{tr.SalesOrder.CustomerAddress}}</vs-td>
                    <vs-td>{{ formatDate(t.Date) }}</vs-td>
                </vs-tr>
            </template>
            </template>
        </vs-table>
        <br>
        <br>
        <vs-table style="width:100%" :data="pickingItem">
            <thead>
                <tr>
                    <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th style="vertical-align: middle;" rowspan="2">HU</th>
                    <th style="vertical-align: middle;" rowspan="2">Quantity</th>
                    <th style="text-align: center;" colspan="3">Suggestion</th>
                </tr>
                <tr>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <template>
                <template v-for="(t, indext) in pickingItem">
                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemSuggestion">
                        <vs-td>{{ tr.SkuCode }}</vs-td>
                        <vs-td>{{ tr.ItemName }}</vs-td>
                        <vs-td>{{ tr.Unit }}</vs-td>
                        <vs-td>{{ tr.Amount }}</vs-td>
                        <vs-td>{{ tr.StorageCode }}</vs-td>
                        <vs-td>{{ tr.Batch }}</vs-td>
                        <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                    </vs-tr>
                </template>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="() => $emit('close')">Close</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
// import Warehouse from "./component/Warehouse.vue";
// import Date from "./component/Date.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        // Warehouse,
        // Date,
        flatPickr,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
        picking_id: Number,
    },
    data() {
        return {
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                // minTime: "00:00",
                plugins: [new ConfirmDatePlugin()],
            },
            // pickingDate: "",
            readonly: true,
            // table: this.tableDefaultState(),
            pickingPlan: [],
            pickingItem: [],
            data: [],
            stock: [],
            selects: {},
        }
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        optionStorage: function () {
            return (itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId).map((p) => p.StorageCode))]
        },
        optionBatch: function () {
            return (storage, itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.StorageCode == storage).map((p) => p.Batch))]
        },
        optionExpired: function () {
            return (batch, itemUnitId) => [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.Batch == batch).map((p) => p.ExpiredDate))]
        },
    },
    methods: {
        // handleSave() {
        //     this.$vs.loading()
        //     const suggestion = this.pickingItem.reduce((sum, p) => [...sum, ...p.PickingItemSuggestion.map((ps) => ({id: ps.id, storage_code: ps.StorageCode, batch: ps.Batch, expired_date: this.formatDate(ps.ExpiredDate)}))], [])
        //     this.$http
        //         .post("/api/wms/v1/simple-outbound-planner/suggestion/save", {suggestion})
        //         .then((resp) => {
        //             if (resp.code == 200) {
        //                 this.$emit("close")
        //                 this.$vs.loading.close();
        //             } else {
        //                 this.$vs.loading.close();
        //             }
        //         });
        // },
    },
    mounted() {
        this.$vs.loading();
        this.$http
            // .get("/api/wms/v1/simple-outbound-planner/suggestion/form", {
            //     params: {
            //         picking_id: this.picking_id,
            //     },
            // })
            .get(`/api/wms/v1/simple-outbound-planner/picking-data/${this.picking_id}`)
            .then((resp) => {
                if (resp.code == 200) {
                    this.data = [resp.data]
                    // this.pickingPlan = resp.data.PickingPlan
                    this.pickingItem = resp.data.PickingItem
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            // .then(() => {
            //     const itemUnitIds = this.pickingItem.reduce((sum, p) => [...sum, ...p.PickingItemSuggestion.map((ps) => ps.ItemUnitID)], [])
            //     return this.$http
            //         .get("/api/wms/v1/simple-outbound-planner/stock", {
            //             params: {
            //                 item_unit_ids: itemUnitIds,
            //                 // picking_date: this.pickingDate,
            //             },
            //         }) 
            // })
            // .then((resp) => {
            //     if (resp.code == 200) {
            //         this.stock = resp.data
            //         this.$vs.loading.close();
            //     } else {
            //         this.$vs.loading.close();
            //     }
            // });
    },
}
</script>
