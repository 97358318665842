<template>
    <vx-card title="Detail Delivery Plan Picking">
        <div class="vx-row mb-6" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Delivery Plan</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <vs-input disabled v-model="dp_code"></vs-input>
            </div>
        </div>
        <vs-table :hoverFlat="false" border :data="data">
            <template slot="thead">
                <vs-th sort-key="">Picking List Number</vs-th>
                <vs-th sort-key="">Confirmed</vs-th>
                <vs-th sort-key="">Picking List Type</vs-th>
                <vs-th sort-key="">Sales Order Data</vs-th>
                <vs-th sort-key="">Delivery Order Data</vs-th>
                <vs-th sort-key="">Ship-to-Data</vs-th>
                <vs-th sort-key="">Request Delivery Data (RDD)</vs-th>
            </template>
            <template v-for="(t, indext) in data">
                <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingPlan">
                    <vs-td>{{ indextr == 0 ? t.Code: '' }}</vs-td>
                    <vs-td>
                        <template v-if="indextr == 0">
                            <vs-icon v-if="t.StatusPicking == 3" color="success" icon-pack="feather" icon="icon-check" />
                            <vs-icon v-else color="danger" icon-pack="feather" icon="icon-x" />
                        </template>
                    </vs-td>
                    <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryType : '' }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{tr.SalesOrder.CustomerAddress}}</vs-td>
                    <vs-td>{{ formatDate(t.Date) }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <br>
        <vs-table style="width:100%" stripe :data="pickingItem">
            <thead>
                <tr>
                    <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th style="vertical-align: middle;" rowspan="2">Confirmed</th>
                    <th style="text-align: center;" colspan="5">Suggestion</th>
                    <th style="text-align: center;" colspan="5">Actual</th>
                </tr>
                <tr>
                    <th>HU</th>
                    <th>Quantity</th>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                    <th>HU</th>
                    <th>Quantity</th>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <tbody>
                <template>
                    <template v-for="(t, indext) in pickingItem">
                        <template v-if="t.PickingItemStock.length > t.PickingItemSuggestion.length">
                            <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemStock">
                                <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                <!-- <vs-td>{{ indextr == 0 ? t.StatusPicking == 3 ? "v" : "x" : ''}}</vs-td> -->
                                <vs-td>
                                    <template v-if="indextr == 0">
                                        <vs-icon v-if="t.StatusPicking == 3" color="success" icon-pack="feather" icon="icon-check" />
                                        <vs-icon v-else color="danger" icon-pack="feather" icon="icon-x" />
                                    </template>
                                </vs-td>
                                <vs-td>{{ t.PickingItemSuggestion.length > indextr ? t.PickingItemSuggestion[indextr].Unit : ''}}</vs-td>
                                <vs-td>{{ t.PickingItemSuggestion.length > indextr ? t.PickingItemSuggestion[indextr].Amount : ''}}</vs-td>
                                <vs-td>{{ t.PickingItemSuggestion.length > indextr ? t.PickingItemSuggestion[indextr].StorageCode : ''}}</vs-td>
                                <vs-td>{{ t.PickingItemSuggestion.length > indextr ? t.PickingItemSuggestion[indextr].Batch : ''}}</vs-td>
                                <vs-td>{{ t.PickingItemSuggestion.length > indextr ? formatDate(t.PickingItemSuggestion[indextr].ExpiredDate) : ''}}</vs-td>
                                <!--  -->
                                <vs-td>{{ tr.ItemUnit }}</vs-td>
                                <vs-td>{{ tr.Amount }}</vs-td>
                                <vs-td>{{ tr.StorageCode }}</vs-td>
                                <vs-td>{{ tr.Batch }}</vs-td>
                                <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                            </vs-tr>
                        </template>
                        <template v-else>
                            <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingItemSuggestion">
                                <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                <!-- <vs-td>{{ indextr == 0 ? t.StatusPicking == 3 ? "v" : "x" : ''}}</vs-td> -->
                                <vs-td>
                                    <template v-if="indextr == 0">
                                        <!-- <feather-icon v-if="t.StatusPicking == 3" icon="x" /> -->
                                        <vs-icon v-if="t.StatusPicking == 3" color="success" icon-pack="feather" icon="icon-check" />
                                        <vs-icon v-else color="danger" icon-pack="feather" icon="icon-x" />
                                    </template>
                                </vs-td>
                                <!--  -->
                                <vs-td>{{ tr.Unit}}</vs-td>
                                <vs-td>{{ tr.Amount}}</vs-td>
                                <vs-td>{{ tr.StorageCode}}</vs-td>
                                <vs-td>{{ tr.Batch}}</vs-td>
                                <vs-td>{{ formatDate(tr.ExpiredDate)}}</vs-td>
                                <!--  -->
                                <template v-if="t.PickingItemStock.length > indextr">
                                    <vs-td>{{ t.PickingItemStock[indextr].ItemUnitID }}</vs-td>
                                    <vs-td>{{ t.PickingItemStock[indextr].Amount }}</vs-td>
                                    <vs-td>{{ t.PickingItemStock[indextr].StorageCode }}</vs-td>
                                    <vs-td>{{ t.PickingItemStock[indextr].Batch }}</vs-td>
                                    <vs-td>{{ t.PickingItemStock[indextr].ExpiredDate }}</vs-td>
                                </template>
                                <template v-else>
                                    <vs-td></vs-td>
                                    <vs-td></vs-td>
                                    <vs-td></vs-td>
                                    <vs-td></vs-td>
                                    <vs-td></vs-td>
                                </template>
                            </vs-tr>
                        </template>
                    </template>
                </template>
            </tbody>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleBack">Back</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
// import Warehouse from "./component/Warehouse.vue";
// import Date from "./component/Date.vue";
import moment from "moment";

export default {
    props: {
        dp_id: Number,
        dp_code: String,
    },
    data() {
        return {
            pickingPlan: [],
            pickingItem: [],
            data: [],
        }
    },
    computed: {
        formatDate: () => {
            return (val) => val ? moment.utc(val).format("YYYY-MM-DD") : ""
        },
    },
    methods: {
        handleBack() {
            console.log("back")
            this.$emit("back")
        },
    },
    mounted() {
        console.log("mounted")
        this.$vs.loading();
        this.$http
            .get(`/api/wms/v1/simple-outbound-planner/picking-dp/${this.dp_id}`)
            .then((resp) => {
                if (resp.code == 200) {
                    this.data = resp.data
                    resp.data.forEach((d) => {
                        this.pickingItem.push(...d.PickingItem)
                        this.pickingPlan.push(...d.PickingPlan)
                    })
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
    },
}
</script>
