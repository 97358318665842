<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="Picking">
            <process @edit="edit"></process>
        </vs-tab>
        <vs-tab label="Edit">
            <div v-if="!allowEdit"></div>
            <edit v-else :picking_id="pickingId" @close="close"></edit>
        </vs-tab>
        <!-- <vs-tab label="Delivery Plan">
            <delivery-plan @detail="detail"></delivery-plan>
        </vs-tab>
        <vs-tab label="Detail">
            <div v-if="!allowDetail"></div>
            <detail v-else :dp_id="dpId" :dp_code="dpCode" @back="back"></detail>
        </vs-tab> -->
    </vs-tabs>
</template>
<script>
import edit from "./edit.vue"
import detail from "./detail.vue"
import process from "./process.vue"
import deliveryPlan from "./deliveryPlan.vue"
export default {
    components: {
        process,
        edit,
        detail,
        deliveryPlan,
    },
    data() {
        return {
            tabs: 0,
            pickingId: null,
            dpId: null,
            dpCode: "",
            allowEdit: false,
            allowDetail: false,
        }
    },
    methods: {
        edit(params) {
            console.log(params)
            this.allowEdit = true
            this.tabs = 1
            this.pickingId = params.picking_id
        },
        detail(params) {
            console.log(params)
            this.allowDetail = true
            this.tabs = 3
            this.dpId = params.dp_id
            this.dpCode = params.dp_code
        },
        close() {
            this.allowEdit = false
            this.tabs = 0
        },
        back() {
            this.allowDetail = false
            this.tabs = 2
        },
    }
}
</script>