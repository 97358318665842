<template>
    <vx-card title="Edit Picking Suggestion Form">
        <div class="vx-row">
            <div class="flex flex-start ml-5" style="width:50%">
                <v-select style="width:100px" :options="table.limits" :clearable="false"  @input="handleChangelength" v-model="params.length" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
            <div class="mr-auto"></div>
            <div class="flex flex-end  " >
                <div class="flex flex-end ">
                <vs-input
                    class="mb-4 md:mb-0 mr-4 round"
                    v-model="params.search"
                    @keyup="handleSearch"
                    placeholder="Search..."
                />
                </div>
            </div>
        </div>
        <div class="vx-col w-full mb-base flex flex-col" v-if="reRenderTable">
            <table class="table table-stripe">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Delivery Plan</th>
                        <th>Total Picking</th>
                        <th>Total Confirmed Picking</th>
                        <th>Total DO</th>
                        <th>#</th>
                        <th>Picking Data</th>
                        <th>Sales Order</th>
                        <th>Delivery Order</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(d, idx) in responseData.records">
                        <tr :key="`outerTable-${idx}`">
                            <td class="space-x-1 flex">
                                <vs-button color="success" type="line" icon-pack="feather" icon="icon-eye" @click="$emit('detail', { dp_id: d.ID, dp_code: d.Code })" />
                                <vs-button :disabled="d.TotalPicking != d.Picking.length" type="line" icon-pack="feather" icon="icon-check" @click="handover(d.ID)" />
                            </td>
                            <td>{{d.Code}}</td>
                            <td>{{d.TotalPicking}}</td>
                            <td>{{d.Picking.length}}</td>
                            <td>{{countArr(d.Picking, "PickingPlan")}}</td>
                            <td>
                                <vs-button
                                    @click="expand(d.Code)"
                                    icon-pack="feather"
                                    :icon="expands[d.Code] ? 'icon-chevrons-down' : 'icon-chevron-right'"
                                    type="flat"
                                    icon-after
                                    color="dark"
                                ></vs-button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <template v-if="expands[d.Code]">
                            <template v-for="(d2, idx2) in d.Picking">
                                <tr :key="`innerTable-${idx}-${idx2}-${idx3}`"  v-for="(d3, idx3) in d2.PickingPlan">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ idx3 == 0 ? d2.Code : '' }}</td>
                                    <td>{{ d3.SalesOrder.Code }}</td>
                                    <td>{{ d3.SalesOrder.DeliveryOrder.Code }}</td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
            <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    props: {
        picking_id: Number,
    },
    data() {
        return {
            reRenderTable: true,
            expands: {},
            responseData: {},
            table: this.tableDefaultState(),
            params: {
                search: "",
                length: 10,
                page: 1,
                order:'',
                sort:'',
            },
        }
    },
    computed: {
        formatDate: () => {
            return (val) => val ? moment.utc(val).format("YYYY-MM-DD") : ""
        },
        setPage: {
            get() {
                return this.table.page;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        countArr: function () {
            return (arr, key) => {
                return arr.reduce((t,n) => t + n[key].length, 0)
            }
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handover(dpId) {
            this.$vs.loading();
            this.$http
                .patch("/api/wms/v1/simple-outbound-planner/handover", {delivery_plan_id: dpId})
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        this.reloadData()
                    } else {
                        this.$vs.loading.close();
                    }
                })
        },
        expand(code) {
            this.expands[code] = !this.expands[code]
            this.reRenderTable = false
            this.$nextTick(() => this.reRenderTable = true)
        },
        handleChangePage(page) {
            if (this.table.page != page) {
                this.table.page = page;
                this.reloadData(this.params);
            }
        },
        handleSearch(e) {
            this.table.search = e.target.value;
            this.table.page = 1;
            this.reloadData(this.params);
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.reloadData(this.params);
        },
        reloadData() {
            this.$vs.loading();
            this.$http
                .get(`/api/wms/v1/simple-outbound-planner/picking-dp`, {
                    // params: { 
                    //     status_picking: 2,
                    //     status_release: 1,
                    //     length: 1,
                    // }
                    params: this.params
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log(resp.data)
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.page = resp.data.page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.responseData= resp.data
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        }
    },
    mounted() {
        this.reloadData()
    },
}
</script>
