<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Delivery Plan</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect
                        v-model="deliveryPlans.selected"
                        :options="deliveryPlans.options"
                        placeholder="Type to search"
                        track-by="code"
                        label="code"
                        :max-height="125"
                        :internal-search="false"
                        @search-change="handleSearchChange"
                        @clear="handleSearchClear"
                    >
                        <span slot="noResult">Oops! No data found</span>
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking List Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect
                        v-model="pickings.selected"
                        :options="pickings.options"
                        placeholder="Type to search"
                        track-by="code"
                        label="code"
                        :max-height="125"
                    ></multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking List Type</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <multiselect
                        v-model="deliveryTypes.selected"
                        :options="deliveryTypes.options"
                        placeholder="Type to search"
                        track-by="name"
                        label="name"
                        :max-height="125"
                    >
                        <span slot="noResult">Oops! No data found</span>
                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>RDD</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <flat-pickr style="width: 100%;" :config="configFlatPickr" v-model="rdd"></flat-pickr>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <div>
                    <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
                        :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
                        <template slot="header">
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div
                                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                                        {{ this.table.total }}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                                        <span>{{ item }}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </template>
                        <template slot="thead">
                            <vs-th>Select All</vs-th>
                            <vs-th sort-key="">Delivery Plan</vs-th>
                            <vs-th sort-key="">Picking List Number</vs-th>
                            <vs-th sort-key="">Picking List Type</vs-th>
                            <vs-th sort-key="">Sales Order Data</vs-th>
                            <vs-th sort-key="">Delivery Order Data</vs-th>
                            <vs-th sort-key="">Ship-to-Data</vs-th>
                            <vs-th sort-key="">Request Delivery Data (RDD)</vs-th>
                        </template>

                        <template slot-scope="{ data }">
                        <template v-for="(t, indext) in data">
                            <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingPlan">
                                <vs-td>
                                    <div v-if="indextr == 0" class="space-x-1 flex">
                                        <!-- <div>
                                            <vs-button
                                            color="success"
                                            type="line"
                                            icon-pack="feather"
                                            @click="print(tr)"
                                            icon="icon-printer"
                                            />
                                        </div> -->
                                        <div>
                                            <vs-button
                                            type="line"
                                            icon-pack="feather"
                                            @click="() => $emit('view', {picking_id: t.id})"
                                            icon="icon-eye"
                                            />
                                        </div>
                                        <vs-checkbox
                                            :checked="selected.includes(t.id)"
                                            v-on:click="addChecked(t.id)"
                                        ></vs-checkbox>
                                    </div>
                                </vs-td>
                                <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.Code : '' }}</vs-td>
                                <vs-td>{{ indextr == 0 ? t.Code: '' }}</vs-td>
                                <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryType : '' }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                                <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                                <vs-td>{{tr.SalesOrder.CustomerAddress}}</vs-td>
                                <vs-td>{{ formatDate(t.Date) }}</vs-td>
                            </vs-tr>
                        </template>
                    </template>
                    </vs-table>
                    <div class="mb-6 space-x-1 flex">
                        <div class="">
                            <vs-button color="primary" @click="handlePrintAll">Print ALL</vs-button>
                        </div>
                        <div class="">
                            <vs-button color="success" @click="handleProcess">PROCESS</vs-button>
                        </div>
                        <div class="">
                            <vs-button color="danger" @click="handleCancel">Cancel</vs-button>
                        </div>
                    </div>
                    <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import moment from "moment"
export default {
    components: {
        flatPickr,
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            selected: [],
            selectedRouteAssignmentToAssign: [],
            activeTab: "New",
            deliveryPlans: {
                selected: {},
                options: [{id: 0, code: "All"}],
            },
            pickings: {
                selected: {},
                options: [{id: 0, code: "All"}],
            },
            deliveryTypes: {
                selected: null,
                options: [],
            },
            rdd: "",
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                plugins: [new ConfirmDatePlugin()],
            },
        }
    },
    methods: {
        handleFilter() {
            this.getData()
        },
        handleSearchChange(searchTerm) {
            this.$http
                .get("/api/wms/v1/master/delivery-plan", {
                    params: {
                        search: searchTerm,
                    },
                })
                    .then((resp) => {
                    if (resp.code == 200) {
                        this.deliveryPlans.options = [{id: 0, code: "All"}, ...resp.data.records];
                        this.$vs.loading.close();
                    } else {
                        console.log("DELIVERY PLANS DATA NOT FOUND");
                    }
                });
        },
        handleSearchClear() {
            this.deliveryPlans.options = [];
        },
        tableDefaultState() {
            return {
                data: [{
                    SKUCode: "89898989",
                    SKUName: "ULI JONGGOL",
                    HU: "uwowowow",
                    Quantity: "98246",
                    storageArea: "MW9NF9P",
                    batchNumber: "823748280374",
                    expDate: "24 JANUARI 2027",
                }],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ?  this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handlePrintAll() {
            // ...
        },
        print() {
            // ...
        },
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/wms/v1/simple-outbound-planner/picking-data", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        picking_id: this.pickings.selected.id,
                        picking_date: this.rdd,
                        // status_picking: "2",
                        // status_picking: 0,
                        status_pickings: [2],
                        status_release: 1,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.selected = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleProcess() {
            console.log(this.selected)
            if (this.selected.length) {
                this.$vs.loading()
                this.$http
                    .patch("/api/wms/v1/simple-outbound-planner/process", {picking_ids: this.selected})
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.getData()
                        } else {
                            this.$vs.loading.close();
                        }
                    })
            }
        },
        handleCancel() {
            console.log("cancel")
            console.log(this.selected)
            if (this.selected.length) {
                this.$vs.loading()
                this.$http
                    .patch("/api/wms/v1/simple-outbound-planner/cancel", {picking_ids: this.selected})
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.getData()
                        } else {
                            this.$vs.loading.close();
                        }
                    })
            }
        },
        handleView() {
            this.$router.push({
                name: "simple-outbound-planner-new-view",
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
        addChecked(pickingId) {
            if (!this.selected.includes(pickingId)) {
                this.selected.push(pickingId)
            } else {
                this.selected.splice(this.selected.indexOf(pickingId), 1)
            }
        },
    },
    mounted() {
        this.$http
            .get("/api/wms/v1/master/delivery-types")
            .then(resp => {
                if (resp.code == 200) {
                    this.deliveryTypes.options = resp.data.records;
                    this.$vs.loading.close();
                } else {
                    console.log("DELIVERY TYPES DATA NOT FOUND")
                }
            })
            // .then(() => this.$http.get("/api/wms/v1/master/picking", {params: {status_picking: "2"}}))
            // .then(() => this.$http.get("/api/wms/v1/master/picking", {params: {status_picking: 0, status_release: 1}}))
            .then(() => this.$http.get("/api/wms/v1/master/picking", {params: {status_pickings: [2], status_release: 1}}))
            .then(res => {
                if (res.code == 200) {
                    this.pickings.options = [{id: 0, code: "All"}, ...res.data.records]
                }
                this.$vs.loading.close();
            });
    },
    watch: {},
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>