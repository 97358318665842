<template>
    <vs-tabs v-model="tabs">
        <vs-tab label="New">
            <tab-new @view="view"></tab-new>
        </vs-tab>
        <vs-tab label="View">
            <div v-if="!allowView"></div>
            <tab-view v-else :picking_id="pickingId" @close="close"></tab-view>
        </vs-tab>
    </vs-tabs>
</template>
<script>
import TabView from "./TabView.vue"
import TabNew from "./TabNew.vue"
export default {
    components: {
        TabNew,
        TabView
    },
    data() {
        return {
            tabs: 0,
            pickingId: null,
            allowView: false,
        }
    },
    methods: {
        view(params) {
            console.log(params)
            this.allowView = true
            this.tabs = 1
            this.pickingId = params.picking_id
        },
        close() {
            this.allowView = false
            this.tabs = 0
        },
    }
}
</script>