<template>
    <vx-card title="Edit Picking Form">
        <!-- <vs-table stripe border :data="pickingPlan"> -->
        <vs-table :hoverFlat="false" border :data="data">
            <!-- <template slot="thead">
                <vs-th sort-key="">Delivery Order</vs-th>
                <vs-th sort-key="">Sales Order</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                <vs-th sort-key="">Delivery Type</vs-th>
            </template> -->
            <template slot="thead">
                <vs-th sort-key="">Delivery Plan</vs-th>
                <vs-th sort-key="">Picking List Number</vs-th>
                <vs-th sort-key="">Picking List Type</vs-th>
                <vs-th sort-key="">Sales Order Data</vs-th>
                <vs-th sort-key="">Delivery Order Data</vs-th>
                <vs-th sort-key="">Ship-to-Data</vs-th>
                <vs-th sort-key="">Request Delivery Data (RDD)</vs-th>
            </template>
            <template v-for="(t, indext) in data">
                <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.PickingPlan">
                    <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryPlanLine.DeliveryPlan.Code : '' }}</vs-td>
                    <vs-td>{{ indextr == 0 ? t.Code: '' }}</vs-td>
                    <vs-td>{{ indextr == 0 ? tr.SalesOrder.DeliveryType : '' }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.Code }}</vs-td>
                    <vs-td>{{ tr.SalesOrder.DeliveryOrder.Code }}</vs-td>
                    <vs-td>{{tr.SalesOrder.CustomerAddress}}</vs-td>
                    <vs-td>{{ formatDate(t.Date) }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <br>
        <vs-tabs v-model="tab">
            <vs-tab label="SKU View">
                <vs-table style="width:100%" stripe :data="pickingItem">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                            <th style="text-align: center;" colspan="2">DO</th>
                            <th style="text-align: center;" colspan="5">Suggestion</th>
                            <th style="text-align: center;" colspan="6">Actual</th>
                            <!-- <th style="vertical-align: middle;" rowspan="2">#</th> -->
                            <th style="text-align: center;" colspan="6">Remaining</th>
                        </tr>
                        <tr>
                            <th>HU</th>
                            <th>Quantity</th>
                            <th>HU</th>
                            <th>Quantity</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>HU</th>
                            <th>Quantity</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>#</th>
                            <th>HU</th>
                            <th>Quantity</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="render">
                            <template v-for="(t, indext) in pickingItem">
                                <!-- <template v-if="t.Actual.length > t.Suggestion.length"> -->
                                <template v-if="maxGroup(indext) == 'picking'">
                                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.Order">
                                        <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                        <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ tr.Unit }}</vs-td>
                                        <vs-td>{{ tr.Amount }}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Amount : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].StorageCode : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Batch : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? formatDate(t.Suggestion[indextr].ExpiredDate) : ''}}</vs-td>
                                        <!--  -->
                                        <template v-if="t.Actual.length > indextr">
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Actual', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-input v-model="t.Actual[indextr].Amount" @change="updateRemaining(indext)" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Actual[indextr].StorageCode, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Actual[indextr].Batch, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <!--  -->
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                            </vs-td>
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                        <!--  -->
                                        <template v-if="t.Remaining.length > indextr">
                                            <!-- <vs-td>{{ t.Remaining[indextr].unit }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Remaining', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Amount }}</vs-td> -->
                                            <vs-td>
                                                <vs-input v-model="t.Remaining[indextr].Amount" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].StorageCode }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Batch }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Remaining[indextr].StorageCode, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].ExpiredDate }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Remaining[indextr].Batch, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                            </vs-td>
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                    </vs-tr>
                                </template>
                                <template v-else-if="maxGroup(indext) == 'actual'">
                                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.Actual">
                                        <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                        <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Amount : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Amount : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].StorageCode : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Batch : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? formatDate(t.Suggestion[indextr].ExpiredDate) : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>
                                            <vs-select v-model="tr.ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Actual', e, indext, indextr)">
                                                <vs-select-item
                                                    v-for="(iu,index) in optionUnit(t.ItemID)"
                                                    :key="index"
                                                    :value="iu.id"
                                                    :text="iu.name" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-input v-model="tr.Amount" @change="updateRemaining(indext)" style="width: 75px;"></vs-input>
                                        </vs-td>
                                        <vs-td>
                                            <vs-select v-model="tr.StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Actual', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionStorage(tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-select v-model="tr.Batch" style="width: 75px;" @change="(e) => changeBatch('Actual', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionBatch(tr.StorageCode, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-select v-model="tr.ExpiredDate" style="width: 100px;">
                                                <vs-select-item
                                                    v-for="(item,index) in optionExpired(tr.Batch, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="formatDate(item)"
                                                    :text="formatDate(item)" />
                                            </vs-select>
                                        </vs-td>
                                        <!--  -->
                                        <vs-td>
                                            <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                            <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                        </vs-td>
                                        <template v-if="t.Remaining.length > indextr">
                                            <!-- <vs-td>{{ t.Remaining[indextr].unit }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Remaining', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Amount }}</vs-td> -->
                                            <vs-td>
                                                <vs-input v-model="t.Remaining[indextr].Amount" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].StorageCode }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Batch }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Remaining[indextr].StorageCode, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].ExpiredDate }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Remaining[indextr].Batch, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                            </vs-td>
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                    </vs-tr>
                                </template>
                                <template v-else-if="maxGroup(indext) == 'suggestion'">
                                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.Suggestion">
                                        <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                        <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Amount : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ tr.Unit}}</vs-td>
                                        <vs-td>{{ tr.Amount}}</vs-td>
                                        <vs-td>{{ tr.StorageCode}}</vs-td>
                                        <vs-td>{{ tr.Batch}}</vs-td>
                                        <vs-td>{{ formatDate(tr.ExpiredDate)}}</vs-td>
                                        <!--  -->
                                        <template v-if="t.Actual.length > indextr">
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Actual', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-input v-model="t.Actual[indextr].Amount" @change="updateRemaining(indext)" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Actual[indextr].StorageCode, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Actual[indextr].Batch, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <!--  -->
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                            </vs-td>
                                            
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                        <template v-if="t.Remaining.length > indextr">
                                            <!-- <vs-td>{{ t.Remaining[indextr].unit }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Remaining', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Amount }}</vs-td> -->
                                            <vs-td>
                                                <vs-input v-model="t.Remaining[indextr].Amount" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].StorageCode }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].Batch }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Remaining', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Remaining[indextr].StorageCode, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <!-- <vs-td>{{ t.Remaining[indextr].ExpiredDate }}</vs-td> -->
                                            <vs-td>
                                                <vs-select v-model="t.Remaining[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Remaining[indextr].Batch, t.Remaining[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Remaining', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Remaining', indext, indextr)"></vs-button>
                                            </vs-td>
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                    </vs-tr>
                                </template>
                                <template v-else>
                                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.Remaining">
                                        <vs-td>{{ indextr == 0 ? t.SkuCode : '' }}</vs-td>
                                        <vs-td>{{ indextr == 0 ? t.ItemName : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Order.length > indextr ? t.Order[indextr].Amount : ''}}</vs-td>
                                        <!--  -->
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Unit : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Amount : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].StorageCode : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? t.Suggestion[indextr].Batch : ''}}</vs-td>
                                        <vs-td>{{ t.Suggestion.length > indextr ? formatDate(t.Suggestion[indextr].ExpiredDate) : ''}}</vs-td>
                                        <!--  -->
                                        <template v-if="t.Actual.length > indextr">
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Actual', e, indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(iu,index) in optionUnit(t.ItemID)"
                                                        :key="index"
                                                        :value="iu.id"
                                                        :text="iu.name" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-input v-model="t.Actual[indextr].Amount" @change="updateRemaining(indext)" style="width: 75px;"></vs-input>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionStorage(t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].Batch" style="width: 75px;" @change="(e) => changeBatch('Actual', indext, indextr)">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionBatch(t.Actual[indextr].StorageCode, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="item"
                                                        :text="item" />
                                                </vs-select>
                                            </vs-td>
                                            <vs-td>
                                                <vs-select v-model="t.Actual[indextr].ExpiredDate" style="width: 100px;">
                                                    <vs-select-item
                                                        v-for="(item,index) in optionExpired(t.Actual[indextr].Batch, t.Actual[indextr].ItemUnitID)"
                                                        :key="index"
                                                        :value="formatDate(item)"
                                                        :text="formatDate(item)" />
                                                </vs-select>
                                            </vs-td>
                                            <!--  -->
                                            <vs-td>
                                                <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Actual', indext)"></vs-button>
                                                <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Actual', indext, indextr)"></vs-button>
                                            </vs-td>
                                        </template>
                                        <template v-else>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                            <vs-td></vs-td>
                                        </template>
                                        <!-- <vs-td>{{ tr.unit }}</vs-td> -->
                                        <vs-td>
                                            <vs-select v-model="tr.ItemUnitID" style="width: 75px;" :name="`unit-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeUnit('Remaining', e, indext, indextr)">
                                                <vs-select-item
                                                    v-for="(iu,index) in optionUnit(t.ItemID)"
                                                    :key="index"
                                                    :value="iu.id"
                                                    :text="iu.name" />
                                            </vs-select>
                                        </vs-td>
                                        <!-- <vs-td>{{ tr.Amount }}</vs-td> -->
                                        <vs-td>
                                            <vs-input v-model="tr.Amount" style="width: 75px;"></vs-input>
                                        </vs-td>
                                        <!-- <vs-td>{{ tr.StorageCode }}</vs-td> -->
                                        <vs-td>
                                            <vs-select v-model="tr.StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Remaining', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionStorage(tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <!-- <vs-td>{{ tr.Batch }}</vs-td> -->
                                        <vs-td>
                                            <vs-select v-model="tr.Batch" style="width: 75px;" @change="(e) => changeBatch('Remaining', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionBatch(tr.StorageCode, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <!-- <vs-td>{{ tr.ExpiredDate }}</vs-td> -->
                                        <vs-td>
                                            <vs-select v-model="tr.ExpiredDate" style="width: 100px;">
                                                <vs-select-item
                                                    v-for="(item,index) in optionExpired(tr.Batch, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="formatDate(item)"
                                                    :text="formatDate(item)" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button color="success" v-if="indextr == 0" icon-pack="feather" icon="icon-plus" @click="addRow('Remaining', indext)"></vs-button>
                                            <vs-button color="danger" v-else icon-pack="feather" icon="icon-minus" @click="removeRow('Remaining', indext, indextr)"></vs-button>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </vs-table>
                <br>
                <div class="mb-6 space-x-1 flex">
                    <div class="">
                        <vs-button color="primary" @click="handleSave(false)">Save</vs-button>
                    </div>
                    <div class="">
                        <vs-button color="success" @click="handleSave(true)">Save & Confirm</vs-button>
                    </div>
                </div>
            </vs-tab>
            <vs-tab label="DO View" >
                <vs-table style="width:100%" stripe :data="pickingPlan">
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>DO Number</th>
                            <th>SKU Name</th>
                            <th>HU</th>
                            <th>Quantity</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                        <!-- <tr>
                            <th style="vertical-align: center;" rowspan="2">Select</th>
                            <th style="vertical-align: center;" rowspan="2">DO Number</th>
                            <th style="vertical-align: center;" rowspan="2">SKU Name</th>
                            <th style="vertical-align: center;" rowspan="2">HU</th>
                            <th style="text-align: center;" rowspan="2">Quantity</th>
                            <th style="text-align: center;" colspan="3">Actual</th>
                            <th style="text-align: center;" colspan="3">Packing</th>
                        </tr>
                        <tr>
                            <th sort-key="">Storage Area</th>
                            <th sort-key="">Batch Number</th>
                            <th sort-key="">Expired Date</th>
                            <th sort-key="">Storage Area</th>
                            <th sort-key="">Batch Number</th>
                            <th sort-key="">Expired Date</th>
                        </tr> -->
                    </thead>

                    <tbody>
                        <template v-if="render">
                            <template v-for="(t, indext) in pickingPlan">
                                <template v-if="t.StatusPicking == 3">
                                    <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.SalesOrder.DeliveryOrder.Packing.PackingLine">
                                        <vs-td>
                                            <vs-checkbox
                                                v-if="indextr == 0"
                                                @click="check(indext)"
                                                v-model="t.selected"
                                            ></vs-checkbox>
                                        </vs-td>
                                        <vs-td>{{ indextr == 0 ? t.SalesOrder.DeliveryOrder.Code : '' }}</vs-td>
                                        <vs-td>{{ tr.ItemName }}</vs-td>
                                        <vs-td>{{ tr.ItemUnit }}</vs-td>
                                        <vs-td>{{ tr.Amount }}</vs-td>
                                        <!-- <vs-td>{{ tr.StorageCode }}</vs-td>
                                        <vs-td>{{ tr.Batch }}</vs-td>
                                        <vs-td>{{ tr.ExpiredDate }}</vs-td> -->
                                        <vs-td>
                                            <vs-select v-model="tr.StorageCode" style="width: 175px;" :name="`storage_code-${indext}-${indextr}`" v-validate="'required'" @change="(e) => changeStorage('Remaining', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionStorageDo(tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-select v-model="tr.Batch" style="width: 75px;" @change="(e) => changeBatch('Remaining', indext, indextr)">
                                                <vs-select-item
                                                    v-for="(item,index) in optionBatchDo(tr.StorageCode, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="item"
                                                    :text="item" />
                                            </vs-select>
                                        </vs-td>
                                        <vs-td>
                                            <vs-select v-model="tr.ExpiredDate" style="width: 100px;">
                                                <vs-select-item
                                                    v-for="(item,index) in optionExpiredDo(tr.Batch, tr.ItemUnitID)"
                                                    :key="index"
                                                    :value="formatDate(item)"
                                                    :text="formatDate(item)" />
                                            </vs-select>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </vs-table>
                <br>
                <div class="mb-6 space-x-1 flex">
                    <div class="">
                        <vs-button color="dark" type="border" @click="reallocate()">Reallocate DO</vs-button>
                    </div>
                    <div class="">
                        <vs-button color="primary" @click="handleSaveDo(false)">Save</vs-button>
                    </div>
                    <div class="">
                        <vs-button color="success" @click="handleSaveDo(true)">Save & Confirm</vs-button>
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>
        
    </vx-card>
</template>

<script>
// import Warehouse from "./component/Warehouse.vue";
// import Date from "./component/Date.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default {
    components: {
        // Warehouse,
        // Date,
        flatPickr,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
        picking_id: Number,
    },
    data() {
        return {
            tab: 0,
            configFlatPickr: {
                altFormat: "F j, Y",
                dateFormat: "j F Y",
                enableTime: false,
                // minTime: "00:00",
                plugins: [new ConfirmDatePlugin()],
            },
            // pickingDate: "",
            readonly: true,
            // table: this.tableDefaultState(),
            pickingPlan: [],
            pickingItem: [],
            data: [],
            stock: [],
            actualStock: [],
            selects: {},
            itemIds: [],
            itemUnit: [],
            render: true,
            deleteActualIdx: [],
            deleteRemainingIdx: [],
        }
    },
    // watch: {
    //     tab(val) {
    //         if (val == 1) {
    //             console.log("DO")
    //         }
    //     },
    // },
    computed: {
        mapPickingItem: function () {
            return (pi) => {
                return Object.keys(pi)
                    .filter(p => {
                        const cond = p.slice(0,6) == "Amount" && pi[p]
                        return cond
                    })
                    .map(p => ({Unit: pi[`Unit${p[p.length-1]}`], Amount: pi[`Amount${p[p.length-1]}`]}))
            }
        },
        maxGroup: function () {
            return (idx) => [
                {
                    group: "picking",
                    total: this.pickingItem[idx].Remaining.length,
                },
                {
                    group: "suggestion",
                    total: this.pickingItem[idx].Suggestion.length,
                },
                {
                    group: "actual",
                    total: this.pickingItem[idx].Actual.length,
                },
                {
                    group: "remaining",
                    total: this.pickingItem[idx].Remaining.length,
                },
            ].reduce((p, c) => p.total > c.total ? p : c).group
        },
        formatDate: () => {
            return (val) => val ? moment.utc(val).format("YYYY-MM-DD") : ""
        },
        optionStorage: function () {
            return (itemUnitId) => {
                let a = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId).map((p) => p.StorageCode))]
                if (a.length == 0) a = [""]
                return a
            }
        },
        optionStorageDo: function () {
            return (itemUnitId) => {
                let a = [...new Set(this.actualStock.filter((s) => s.ItemUnitID == itemUnitId).map((p) => p.StorageCode))]
                if (a.length == 0) a = [""]
                return a
            }
        },
        optionBatch: function () {
            return (storage, itemUnitId) => {
                let a = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.StorageCode == storage).map((p) => p.Batch))]
                if (a.length == 0) a = [""]
                return a
            }
        },
        optionBatchDo: function () {
            return (storage, itemUnitId) => {
                let a = [...new Set(this.actualStock.filter((s) => s.ItemUnitID == itemUnitId && s.StorageCode == storage).map((p) => p.Batch))]
                if (a.length == 0) a = [""]
                return a
            }
        },
        optionExpired: function () {
            return (batch, itemUnitId) => {
                let a = [...new Set(this.stock.filter((s) => s.ItemUnitID == itemUnitId && s.Batch == batch).map((p) => p.ExpiredDate))]
                if (a.length == 0) a = [null]
                return a
            }
        },
        optionExpiredDo: function () {
            return (batch, itemUnitId) => {
                let a = [...new Set(this.actualStock.filter((s) => s.ItemUnitID == itemUnitId && s.Batch == batch).map((p) => p.ExpiredDate))]
                if (a.length == 0) a = [null]
                return a
            }
        },
        optionUnit: function () {
            return (itemId) => {
                const a = this.itemUnit.filter((i) => i.item_id == itemId)
                return a
            }
        },
        getAmountUom: function () {
            return (itemUnitId) => {
                const iu = this.itemUnit.filter(iu => iu.id == itemUnitId)[0]
                if (iu) {
                    return iu.amount_uom
                }
                return 0
            }
        }
    },
    methods: {
        check(idx) {
            this.pickingPlan[idx].selected = !this.pickingPlan[idx].selected
            this.render = false
            this.$nextTick(() => this.render = true)
        },
        updateRemaining(idx) {
            let r = []
            let totalActual, totalSuggestion, totalRemaining
            totalActual = totalSuggestion = 0
            const {Actual, Suggestion, ItemID} = this.pickingItem[idx]
            const itemUnit = this.itemUnit.filter((i) => i.item_id == ItemID).sort((a, b) => b.level - a.level)
            const remaining = this.pickingItem[idx].Remaining
            Actual.forEach(a => {
                totalActual += a.Amount * this.getAmountUom(a.ItemUnitID)
            })
            Suggestion.forEach(s => {
                totalSuggestion += s.Amount * this.getAmountUom(s.ItemUnitID)
            })
            totalRemaining = totalActual - totalSuggestion
            itemUnit.forEach(i => {
                const amt = Math.floor(Math.abs(totalRemaining) / i.amount_uom) * (totalRemaining < 0 ? -1 : 1)
                totalRemaining -= amt * i.amount_uom
                if (amt) {
                    const stock = this.stock.filter((s) => s.ItemUnitID == i.id)[0]
                    const prevRe = remaining.filter(re => re.ItemUnitID == i.id)[0]
                    r.push({
                        id: prevRe ?  prevRe.id : 0,
                        Unit: i.name,
                        ItemUnitID: i.id,
                        Amount: amt,
                        AmountUom: i.amount_uom,
                        StorageCode: stock.StorageCode,
                        Batch: stock.Batch,
                        ExpiredDate: this.formatDate(stock.ExpiredDate),
                    })
                }
            })
            remaining.forEach(pr => {
                if (!r.some(re => re.ItemUnitID == pr.ItemUnitID) && pr.id) this.deleteRemainingIdx.push(pr.id)
            })
            // if (r.length == 0) {
            //     Actual.forEach(a => {
            //         r.push({
            //             Unit: a.Unit,
            //             ItemUnitID: a.ItemUnitID,
            //             Amount: 0,
            //             AmountUom: 0,
            //             StorageCode: a.StorageCode,
            //             Batch: a.Batch,
            //             ExpiredDate: this.formatDate(a.ExpiredDate),
            //         })
            //     })
            // }
            this.pickingItem[idx].Remaining=r
            this.render = false
            this.$nextTick(() => this.render = true)
        },
        addRow(group, idx) {
            this.pickingItem[idx][group].push({Unit: '', Amount: 0, StorageCode: '', Batch: '', ExpiredDate: '', ItemUnitID: 0})
            // this.updateRemaining(idx)
            this.render = false
            // this.$nextTick(() => {this.render = true})
            this.$nextTick(() => {this.render = true; group == "Remaining" || this.updateRemaining(idx)})
        },
        removeRow(group, idx, idx2) {
            this.render = false
            const id = this.pickingItem[idx][group][idx2].id
            if (group == "Actual") this.deleteActualIdx.push(id)
            // else this.deleteRemainingIdx.push(id)
            // this.updateRemaining(idx)
            this.pickingItem[idx][group].splice(idx2, 1)
            // this.$nextTick(() => this.render = true)
            this.$nextTick(() => {this.render = true;group == "Remaining" || this.updateRemaining(idx)})
        },
        changeUnit(group, itemUnitId, idx, idx2) {
                this.pickingItem[idx][group][idx2].Unit = this.itemUnit.find((i) => i.id == itemUnitId).name
                this.pickingItem[idx][group][idx2].Amount = 0
                this.pickingItem[idx][group][idx2].StorageCode = ""
                this.pickingItem[idx][group][idx2].Batch = ""
                this.pickingItem[idx][group][idx2].ExpiredDate = ""
                if (group == "Actual") this.updateRemaining(idx)
        },
        changeStorage(group, idx, idx2) {
                this.pickingItem[idx][group][idx2].Batch = ""
                this.pickingItem[idx][group][idx2].ExpiredDate = ""
                // this.updateRemaining(idx)
        },
        changeBatch(group, idx, idx2) {
                this.pickingItem[idx][group][idx2].ExpiredDate = ""
                // this.updateRemaining(idx)
        },
        handleSave(confirm) {
            const actual = this.pickingItem.reduce((p, t) => [...p, ...t.Actual.map(t2 => ({
                id: t2.id, picking_item_id: t.id, item_unit_id: t2.ItemUnitID, sku_code: t.SkuCode,
                item_name: t.ItemName, unit: t2.Unit, batch: t2.Batch, amount: parseInt(t2.Amount),
                storage_code: t2.StorageCode, expired_date: t2.ExpiredDate
            }))], [])
            const remaining = this.pickingItem.reduce((p, t) => [...p, ...t.Remaining.map(t2 => ({
                id: t2.id, picking_item_id: t.id, item_unit_id: t2.ItemUnitID, sku_code: t.SkuCode,
                item_name: t.ItemName, unit: t2.Unit, batch: t2.Batch, amount: parseInt(t2.Amount),
                storage_code: t2.StorageCode, expired_date: t2.ExpiredDate
            }))], [])
            let valid = true
            actual.forEach(a => {
                if (!(a.unit && a.item_unit_id && a.amount && a.storage_code && a.batch && a.expired_date)) {
                    valid = false
                    return
                }
            })
            remaining.forEach(r => {
                if (!(r.unit && r.item_unit_id && r.amount && r.storage_code && r.batch && r.expired_date)) {
                    valid = false
                    return
                }
            })
            if (valid) {
                this.$vs.loading()
                this.$http
                .post("/api/wms/v1/simple-outbound-planner/process/sku/save", {
                    picking_id: this.picking_id,
                    actual,
                    deleted_actual_ids: this.deleteActualIdx,
                    remaining,
                    deleted_remaining_ids: this.deleteRemainingIdx,
                    confirm,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (confirm) this.getData()
                        else this.$emit('close')
                        this.deleteActualIdx = []
                        this.deleteRemainingIdx = []
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
            }
        },
        handleSaveDo(confirm) {
            if (this.pickingPlan.filter(p => !p.selected).length == 0) {
                const packing = this.pickingPlan.filter(p => p.selected).map((p) => ({
                    id: p.SalesOrder.DeliveryOrder.Packing.id,
                    delivery_order_id: p.SalesOrder.DeliveryOrder.id,
                    warehouse_id: p.SalesOrder.WarehouseID,
                    picking_id: this.picking_id,
                    packing_line: p.SalesOrder.DeliveryOrder.Packing.PackingLine.map(pal => ({
                        id: pal.id,
                        item_unit_id: pal.ItemUnitID,
                        item_unit: pal.ItemUnit,
                        sku_code: pal.SkuCode,
                        item_name: pal.ItemName,
                        amount: pal.Amount,
                        batch: pal.Batch,
                        storage_code: pal.StorageCode,
                        expired_date: pal.ExpiredDate,
                    }))
                }))
                this.$vs.loading()
                this.$http
                .post("/api/wms/v1/simple-outbound-planner/process/do/save", {
                    packing,
                    confirm,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                            this.$emit('close')
                            this.$vs.loading.close();
                        } else {
                            this.$vs.loading.close();
                        }
                    })
            }
        },
        reallocate() {
            const ppId = this.pickingPlan.filter(p => !p.selected).map(pp => pp.id)
            this.$vs.loading()
            this.$http
                .patch("/api/wms/v1/simple-outbound-planner/cancel/do", {picking_id: this.picking_id, picking_plan_ids: ppId})
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$emit('close')
                        // this.getData()
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(`/api/wms/v1/simple-outbound-planner/picking-data/${this.picking_id}`, {
                    params: { include_stock: 1, picking_plan_status_picking: 3 }
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        const itemIds = []
                        // const allocatedPis = []
                        this.data = [resp.data]
                        this.pickingItem = resp.data.PickingItem.map((p) => {
                            if (this.tab == 0 && p.StatusPicking == 1) {
                                this.tab = 1
                            }
                            const pi = { ItemID: p.ItemID, SkuCode: p.SkuCode, ItemName: p.ItemName, id:p.id }
                            itemIds.push(pi.ItemID)
                            pi.Suggestion = p.PickingItemSuggestion.map(s => ({...s, ExpiredDate: this.formatDate(s.ExpiredDate)}))
                            if (p.PickingItemStock.length == 0) {
                                let suggestion = JSON.parse(JSON.stringify(p.PickingItemSuggestion))
                                pi.Actual = []
                                suggestion.forEach(c => {
                                    let cs = c
                                    cs.id = 0
                                    cs.ExpiredDate = this.formatDate(cs.ExpiredDate)
                                    pi.Actual.push(cs)
                                })
                            } else {
                                pi.Actual = p.PickingItemStock.map(s => ({...s, ExpiredDate: this.formatDate(s.ExpiredDate)}))
                            }
                            pi.Remaining = p.PickingItemLeftover.map(s => ({...s, ExpiredDate: this.formatDate(s.ExpiredDate)}))
                            pi.Order = this.mapPickingItem(p)
                            return pi
                        })
                        this.itemIds = itemIds
                        this.pickingPlan = resp.data.PickingPlan.map(p => {
                            const pp = p
                            pp.selected = true
                            if (!pp.SalesOrder.DeliveryOrder.Packing.PackingLine) {
                                console.log("_----_s")
                                // const sl = []
                                const pa = {
                                    PickingID: this.picking_id,
                                    WarehouseID: pp.SalesOrder.WarehouseID,
                                    DeliveryOrderID: pp.SalesOrder.DeliveryOrder.id,
                                    PackingLine: []
                                }
                                p.PickingLine.forEach((pl) => {
                                    if (pl.PickingItemActual.length > 0) {
                                        pl.PickingItemActual.forEach(pis => {
                                            console.log("pis", pis)
                                            // if (!allocatedPis.includes(pis.id)) {
                                                const pal = {
                                                    ItemUnitID: pis.ItemUnitID,
                                                    ItemUnit: pis.Unit,
                                                    SkuCode: pis.SkuCode,
                                                    ItemName: pis.ItemName,
                                                    // Amount: pis.Amount,
                                                    Amount: pl.Amount,
                                                    Batch: pis.Batch,
                                                    StorageCode: pis.StorageCode,
                                                    ExpiredDate: this.formatDate(pis.ExpiredDate),
                                                }
                                                pa.PackingLine.push(pal)
                                                // allocatedPis.push(pis.id)
                                            // }
                                        })
                                    // } else {
                                    //     pp.selected = false
                                    //     const pal = {
                                    //         ItemUnitID: 0,
                                    //         ItemUnit: "",
                                    //         SkuCode: "",
                                    //         ItemName: "",
                                    //         Amount: 0,
                                    //         Batch: "",
                                    //         StorageCode: "",
                                    //         ExpiredDate: "",
                                    //     }
                                    //     pa.PackingLine.push(pal)
                                    }
                                })
                                console.log("pa", pa)
                                pp.SalesOrder.DeliveryOrder.Packing = pa
                            // if (!pp.SalesOrder.DeliveryOrder.Packing.PackingLine) {
                            //     const sl = []
                            //     const pa = {
                            //         PickingID: this.picking_id,
                            //         WarehouseID: pp.SalesOrder.WarehouseID,
                            //         DeliveryOrderID: pp.SalesOrder.DeliveryOrder.id,
                            //         PackingLine: []
                            //     }
                            //     p.SalesOrder.SalesOrderLine.forEach((sol) => {
                            //         // pp.StorageCode = pickingItem.filter(p => p.SkuCode == tr.ItemCode).PickingItemStock.filter(pis => pis.ItemUnitID == tr.ItemUnitID)
                            //         const s = this.pickingItem.filter(i => i.SkuCode == sol.ItemCode)[0]
                            //         if (!s) {
                            //             return
                            //         }
                            //         const st = s.Actual.filter(pis => pis.ItemUnitID == sol.ItemUnitID)[0]
                            //         if (!st) {
                            //             return
                            //         }
                            //         const pal = {
                            //             ItemUnitID: sol.ItemUnitID,
                            //             ItemUnit: sol.ItemUnit,
                            //             SkuCode: sol.ItemCode,
                            //             ItemName: sol.ItemName,
                            //             Amount: sol.Quantity,
                            //             Batch: st.Batch,
                            //             StorageCode: st.StorageCode,
                            //             ExpiredDate: this.formatDate(st.ExpiredDate),
                            //         }
                            //         pa.PackingLine.push(pal)
                            //     })
                            //     pp.SalesOrder.DeliveryOrder.Packing = pa
                            } else {
                                // pp.selected = true
                                pp.SalesOrder.DeliveryOrder.Packing.PackingLine = pp.SalesOrder.DeliveryOrder.Packing.PackingLine.map(pl => ({...pl, ExpiredDate: this.formatDate(pl.ExpiredDate)}))
                            }
                            return pp
                        })
                    }
                })
                .then(() => {
                    // const itemUnitIds = this.pickingItem.reduce((sum, p) => [...sum, ...p.Suggestion.map((ps) => ps.ItemUnitID)], [])
                    const skuCode = this.pickingItem.map(pi => pi.SkuCode)
                    return this.$http
                        .get("/api/wms/v1/simple-outbound-planner/stock", {
                            params: {
                                // item_unit_ids: itemUnitIds,
                                sku_codes: skuCode,
                                warehouse_id: this.data[0].WarehouseID,
                                // picking_date: this.pickingDate,
                            },
                        }) 
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.stock = resp.data
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .then(() => {
                    const pickingItemIds = this.pickingItem.map(pi => pi.id)
                    return this.$http
                        .get("/api/wms/v1/simple-outbound-planner/actual-stock", {
                            params: {
                                picking_item_ids: pickingItemIds,
                            },
                        }) 
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.actualStock = resp.data
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .then(() => this.$http.get("/api/wms/v1/master/item-unit", {
                    params: {
                        item_ids: this.itemIds,
                    },
                }))
                .then((resp) => {
                    if (resp.code == 200) {
                        this.itemUnit = resp.data.records
                        // this.pickingItem = this.pickingItem.map((p) => {
                        //     const remaining = []
                        //     p.Actual.forEach(a => {
                        //         remaining.push({
                        //             Unit: a.Unit,
                        //             ItemUnitID: a.ItemUnitID,
                        //             Amount: 0,
                        //             AmountUom: 0,
                        //             StorageCode: a.StorageCode,
                        //             Batch: a.Batch,
                        //             ExpiredDate: this.formatDate(a.ExpiredDate),
                        //         })
                        //     })
                        //     p.Remaining = remaining
                        //     return p
                        // })
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    mounted() {
        this.getData()
    },
}
</script>
